.event-header {
	padding-bottom: 10px;
	width: 100%;
}

.event-stream {
	display: inline-block;
}

.event-chat {
	margin-left: 15px;
	width: 250px;
	display: inline-block;
	vertical-align: top;
}
